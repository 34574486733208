import React, { useEffect } from "react";
import $ from "jquery";
import AdsComp from "./adscom";
import "assets/css/minigame.css";
import { Icon, Modal } from "semantic-ui-react";
const AdsComponent = (props) => {
  useEffect(() => {
    (function () {
      var Memory = {
        init: function (cards) {
          this.$game = $(".game");
          this.$modal = $(".gamemodal");
          this.$overlay = $(".modal-overlay");
          this.$restartButton = $("button.restart");
          this.cardsArray = $.merge(cards, cards);
          this.shuffleCards(this.cardsArray);
          this.setup();
        },

        shuffleCards: function (cardsArray) {
          this.$cards = $(this.shuffle(this.cardsArray));
        },

        setup: function () {
          this.html = this.buildHTML();
          this.$game.html(this.html);
          this.$memoryCards = $(".gamecard");
          this.paused = false;
          this.guess = null;
          this.binding();
        },

        binding: function () {
          this.$memoryCards.on("click", this.cardClicked);
          this.$restartButton.on("click", $.proxy(this.reset, this));
        },
        // kinda messy but hey
        cardClicked: function () {
          var _ = Memory;
          var $card = $(this);
          if (
            !_.paused &&
            !$card.find(".inside").hasClass("matched") &&
            !$card.find(".inside").hasClass("picked")
          ) {
            $card.find(".inside").addClass("picked");
            if (!_.guess) {
              _.guess = $(this).attr("data-id");
            } else if (
              _.guess == $(this).attr("data-id") &&
              !$(this).hasClass("picked")
            ) {
              $(".picked").addClass("matched");
              _.guess = null;
            } else {
              _.guess = null;
              _.paused = true;
              setTimeout(function () {
                $(".picked").removeClass("picked");
                Memory.paused = false;
              }, 600);
            }
            if ($(".matched").length == $(".gamecard").length) {
              _.win();
            }
          }
        },

        win: function () {
          this.paused = true;
          setTimeout(function () {
            Memory.showModal();
            Memory.$game.fadeOut();
            $("#showadsmodget").trigger("click");
          }, 1000);
        },

        showModal: function () {
          this.$overlay.show();
          this.$modal.fadeIn("slow");
        },

        hideModal: function () {
          this.$overlay.hide();
          this.$modal.hide();
        },

        reset: function () {
          this.hideModal();
          this.shuffleCards(this.cardsArray);
          this.setup();
          this.$game.show("slow");
        },

        // Fisher--Yates Algorithm -- https://bost.ocks.org/mike/shuffle/
        shuffle: function (array) {
          var counter = array.length,
            temp,
            index;
          // While there are elements in the array
          while (counter > 0) {
            // Pick a random index
            index = Math.floor(Math.random() * counter);
            // Decrease counter by 1
            counter--;
            // And swap the last element with it
            temp = array[counter];
            array[counter] = array[index];
            array[index] = temp;
          }
          return array;
        },

        buildHTML: function () {
          var frag = "";
          this.$cards.each(function (k, v) {
            frag +=
              '<div class="gamecard" data-id="' +
              v.id +
              '"><div class="inside">\
            <div class="front"><img src="' +
              v.img +
              '"\
            alt="' +
              v.name +
              '" /></div>\
            <div class="back"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/74196/codepen-logo.png"\
            alt="Codepen" /></div></div>\
            </div>';
          });
          return frag;
        },
      };

      var cards = [
        {
          name: "php",
          img: "/assets/images/games/PlatoPool.webp",
          id: 1,
        },
        {
          name: "css3",
          img: "/assets/images/games/PlatoChess.webp",
          id: 2,
        },
        {
          name: "html5",
          img: "/assets/images/games/PlatoSoccer.webp",
          id: 3,
        },
        {
          name: "jquery",
          img: "/assets/images/games/Fifa2023.webp",
          id: 4,
        },
        {
          name: "javascript",
          img: "/assets/images/games/ClashRoyale.webp",
          id: 5,
        },
        {
          name: "node",
          img: "/assets/images/games/BrawlStars.webp",
          id: 6,
        },
      ];

      Memory.init(cards);
    })();
  }, []);

  return (
    <>
      <div className="wrap">
        <div className="game"></div>

        <Modal.Header
          className="header-text"
          style={{
            color: "#fff",
            fontSize: 22,
            color: "gold",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Play and win to get 100 Diamonds rewards.
        </Modal.Header>
        <AdsComp dataAdSlot="7427191858" />
        <div className="modal-overlay">
          <div className="gamemodal">
            <h2 className="winner">You Won!</h2>
            <button className="restart">Play Again?</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdsComponent;
