import eventBus from "views/eventBus";
import SockJS from "sockjs-client";
import Stomp from "stompjs";

var stompClient = null;
//let stompClient = Client;
var socket = null;
function sendMessage(message) {
  try {
    const iframe = document.querySelector("iframe[name=gameframe]");
    iframe.contentWindow.postMessage(message, "*");
  } catch (error) {}
}
class UserWebsocket {
  connect(token) {
    //console.log("socket connected " + JSON.stringify(socket));
    if (socket == null) {
      //  socket = new SockJS("http://139.99.144.72:8080/websocket?token=" + token);
      let host =
        process.env.NODE_ENV === "production"
          ? "loole.money/"
          : // "139.99.144.72:8880";
            "loole.money/";
      let protocol = document.location.protocol;
      protocol = "https:";

      // host = host + ":" + port;
      //protocol = "ws://";
      let loc = protocol + "//" + host;
      // alert(loc);
      socket = new SockJS(loc + "websocket?token=" + token);
      stompClient = Stomp.over(socket);
      // stompClient.heartbeat.outgoing = 20000;
      // stompClient.heartbeat.incoming = 20000;
      stompClient.connect(
        {},
        function () {
          // console.log('Connected: ' + frame);
          eventBus.dispatch("eventsConnect", "");
          stompClient.subscribe("/receive/message", function (data) {
            //   showMessage(JSON.parse(message.body).content);
            var message = JSON.parse(data.body).content;
            function isJson(str) {
              try {
                JSON.parse(str);
              } catch (e) {
                return false;
              }
              return true;
            }
            if (isJson(message)) {
              var msg = JSON.parse(message);
              //console.log(msg.Command);
              if (msg.Command === "event") {
                eventBus.dispatch("updateAllEvents", msg.data);
              } else if (msg.Command === "updateUser") {
                eventBus.dispatch("eventsDataUser", msg.data);
                var newu = {
                  username: msg.data.username,
                  balance: msg.data.balance,
                  balance2: msg.data.point,
                  image: 0,
                };
                //console.log(newu);
                sendMessage(newu);
              } else if (msg.Command === "eventId") {
                eventBus.dispatch("updateEventId", msg.data);
              } else if (
                msg.Command === "chat" ||
                msg.Command === "matchChat"
              ) {
                var _d = {
                  eventId: msg.eventId,
                  chat: msg.chat,
                  matchChat: msg.matchChat,

                  matchId: msg.matchId,
                };
                eventBus.dispatch("updatechat", _d);
              }
            } else {
              if (message === "closeConnection") {
                disconnect();
              } else if (message === "PasswordChanged") {
                eventBus.dispatch(
                  "eventsDataPass",
                  "Your password has been updated."
                );
              } else if (message === "AccountActivated") {
                eventBus.dispatch(
                  "eventsDataActive",
                  "Your account has been activated."
                );
              }
            }
          });

          stompClient.subscribe(
            "/user/receive/private-message",
            function (data) {
              //  showMessage(JSON.parse(data.body).content);
              var message = JSON.parse(data.body).content;
              function isJson(str) {
                try {
                  JSON.parse(str);
                } catch (e) {
                  return false;
                }
                return true;
              }
              if (isJson(message)) {
                var msg = JSON.parse(message);
                //console.log(msg.Command);
                if (msg.Command === "event") {
                  eventBus.dispatch("updateAllEvents", msg.data);
                } else if (msg.Command === "updateUser") {
                  eventBus.dispatch("eventsDataUser", msg.data);
                  var newu = {
                    username: msg.data.username,
                    balance: msg.data.balance,
                    balance2: msg.data.point,
                    image: 0,
                  };
                  // console.log(newu);
                  sendMessage(newu);
                } else if (msg.Command === "eventId") {
                  eventBus.dispatch("updateEventId", msg.data);
                }
              } else {
                if (message === "closeConnection") {
                  disconnect();
                } else if (message === "PasswordChanged") {
                  eventBus.dispatch(
                    "eventsDataPass",
                    "Your password has been updated."
                  );
                } else if (message === "AccountActivated") {
                  eventBus.dispatch(
                    "eventsDataActive",
                    "Your account has been activated."
                  );
                }
              }
            }
          );

          stompClient.subscribe("/receive/global-notification", function () {
            //  notificationCount = notificationCount + 1;
            // updateNotificationDisplay(message);
          });

          stompClient.subscribe(
            "/user/receive/private-notification",
            function () {
              //   notificationCount = notificationCount + 1;
              //   updateNotificationDisplay(message);
            }
          );
        },
        function (message) {
          // check message for disconnect
          //alert(message);
          //eventBus.dispatch("eventsDC", "");
        }
      );
      socket.onclose = function () {
        console.log("websocket closed");
        socket = null;
        stompClient.disconnect();
        if (process.env.NODE_ENV === "production") {
          eventBus.dispatch("eventsDC", "");
        }
      };
    }
  }

  disconnect() {
    //socket = null;
    stompClient.disconnect();
  }
  /*  showMessage(message) {
  $("#messages").append("<tr><td>" + message + "</td></tr>");
}

 showNotification(message) {
  $("#messages").append("<tr><td>Notification: " + message + "</td></tr>");
}

 sendMessage() {
  console.log("sending message");
  stompClient.send("/ws/message", {}, JSON.stringify({'messageContent': $("#message").val()}));
}

 sendPrivateMessage() {
  console.log("sending private message");
  stompClient.send("/ws/private-message", {}, JSON.stringify({'messageContent': $("#private-message").val()}));
}

 updateNotificationDisplay(message) {
  if (notificationCount == 0) {
      $('#notifications').hide();
  } else {
      $('#notifications').show();
      $('#notifications').text(notificationCount);
      showNotification(JSON.parse(message.body).content);
  }
}

 resetNotificationCount() {

  notificationCount = 0;
  updateNotificationDisplay();
}*/
}

export default new UserWebsocket();
