import React, { useEffect, useState } from "react";
var googleInit = null;
const AdsComponent = (props) => {
  const { dataAdSlot } = props;

  useEffect(() => {
    googleInit = setTimeout(() => {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (error) {}
    }, 500);

    return () => {
      clearTimeout(googleInit);
    };
  }, []);
  if (process.env.NODE_ENV !== "production") {
    return null;
  }
  return (
    <>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-7264153250850834"
        data-ad-slot={dataAdSlot}
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <amp-ad
        width="100vw"
        height="320"
        type="adsense"
        data-ad-client="ca-pub-7264153250850834"
        data-ad-slot={dataAdSlot}
        data-auto-format="rspv"
        data-full-width=""
      >
        <div overflow=""></div>
      </amp-ad>
    </>
  );
};

export default AdsComponent;
