import React, { useEffect, useState, useContext } from "react";

import $ from "jquery";
// react-bootstrap components
import UserContext from "context/UserState";
var canSHow = true;
function show(prop) {
  if (!canSHow) return false;
  canSHow = false;
  console.log("show");

  document.getElementById("watchAdButton").onclick = function () {
    prop.onUpdateItem("openModalChart", true);
    dismissRewardedAd();
  };
  document.getElementById("closeadButton").onclick = function () {
    dismissRewardedAd();
  };
  document.getElementById("closeadButton2").onclick = function () {
    dismissRewardedAd();
  };
  displayModal("reward", "Watch an ad to receive a special reward?");

  function dismissRewardedAd() {
    displayModal();
  }

  function displayModal(type, message) {
    var modal = document.getElementById("modal");
    modal.removeAttribute("data-type");

    if (type) {
      document.getElementById("modalMessage").textContent = message;
      modal.setAttribute("data-type", type);
    }
  }
}
function profile(prop) {
  const context = useContext(UserContext);
  const { currentUser } = context.uList;

  useEffect(() => {
    if (currentUser?.point < 100 && currentUser?.accessToken) {
      show(prop);
    } else {
      canSHow = true;
    }
  }, [currentUser?.point, window.location.href]);
  useEffect(() => {
    //console.log(currentUser);
    if (currentUser?.point < 100 && currentUser?.accessToken) {
      show(prop);
    }
  }, []);

  return <></>;
}

export default profile;
